var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId},on:{"showModal":_vm.showModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.getTitleText)+" ")])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"flex items-center gap-2"},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.title.taxName'
            ),"label":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.title.taxName'
            ),"infoDescription":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.infoDescription.taxName'
            )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('AppInput',{attrs:{"type":"number","step":"0.001","rules":"required|between:0,100","name":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.title.taxPercentage'
            ),"label":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.title.taxPercentage'
            ),"infoDescription":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.infoDescription.taxPercentage'
            ),"haveUnitText":true,"unitText":"%"},model:{value:(_vm.form.percentage),callback:function ($$v) {_vm.$set(_vm.form, "percentage", _vm._n($$v))},expression:"form.percentage"}})],1),_c('AppInput',{attrs:{"type":"textarea","name":_vm.$t(
            'components.taxPlanManagement.addEdit.steps.details.title.taxDescription'
          ),"label":_vm.$t(
            'components.taxPlanManagement.addEdit.steps.details.title.taxDescription'
          ),"infoDescription":_vm.$t(
            'components.taxPlanManagement.addEdit.steps.details.infoDescription.taxDescription'
          )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('label',{staticClass:"block mt-6 w-full font-semibold text-14px"},[_c('TCheckbox',{attrs:{"name":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.title.enableTaxPlanCheckbox'
            ),"label":_vm.$t(
              'components.taxPlanManagement.addEdit.steps.details.title.enableTaxPlanCheckbox'
            ),"wrapped":""},model:{value:(_vm.form.status_active),callback:function ($$v) {_vm.$set(_vm.form, "status_active", $$v)},expression:"form.status_active"}})],1),_c('div',{staticClass:"flex justify-end items-center mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.isEditing ? _vm.$t('components.stepNavigation.update') : _vm.$t('components.stepNavigation.save')))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }