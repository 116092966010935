<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        {{ getTitleText }}
      </h2>
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <div class="flex items-center gap-2">
          <AppInput
            v-model="form.name"
            type="text"
            rules="required"
            :name="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.title.taxName'
              )
            "
            :label="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.title.taxName'
              )
            "
            :infoDescription="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.infoDescription.taxName'
              )
            "
          />
          <AppInput
            v-model.number="form.percentage"
            type="number"
            step="0.001"
            rules="required|between:0,100"
            :name="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.title.taxPercentage'
              )
            "
            :label="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.title.taxPercentage'
              )
            "
            :infoDescription="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.infoDescription.taxPercentage'
              )
            "
            :haveUnitText="true"
            unitText="%"
          />
        </div>
        <AppInput
          v-model="form.description"
          type="textarea"
          :name="
            $t(
              'components.taxPlanManagement.addEdit.steps.details.title.taxDescription'
            )
          "
          :label="
            $t(
              'components.taxPlanManagement.addEdit.steps.details.title.taxDescription'
            )
          "
          :infoDescription="
            $t(
              'components.taxPlanManagement.addEdit.steps.details.infoDescription.taxDescription'
            )
          "
        />
        <label class="block mt-6 w-full font-semibold text-14px">
          <TCheckbox
            v-model="form.status_active"
            :name="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.title.enableTaxPlanCheckbox'
              )
            "
            :label="
              $t(
                'components.taxPlanManagement.addEdit.steps.details.title.enableTaxPlanCheckbox'
              )
            "
            wrapped
          />
        </label>

        <div class="flex justify-end items-center mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary">{{
              $t('components.stepNavigation.cancel')
            }}</anchor-button>
          </slide-over-right-trigger>
          <t-button type="submit" class="ml-3">{{
            isEditing
              ? $t('components.stepNavigation.update')
              : $t('components.stepNavigation.save')
          }}</t-button>
        </div>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { TaxConfig } from '@/config/TaxConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

export default {
  name: 'AddTaxPlan',
  components: {
    SlideOverRight,
    SlideOverRightTrigger,
    AnchorButton,
  },
  data() {
    return {
      isLoading: false,
      item: {},
      form: {},
      errors: [],
      sorId: TaxConfig.events.sorId,
    }
  },
  computed: {
    isEditing: function() {
      return Object.keys(this.item).length !== 0
    },
    getTitleText() {
      return this.isEditing
        ? this.$t('components.taxPlanManagement.addEdit.headline.update')
        : this.$t('components.taxPlanManagement.addEdit.headline.add')
    },
  },
  mounted() {
    console.log({ e: TaxConfig.events.editingData })

    EventBus.$on(TaxConfig.events.editingData, (item) => {
      this.item = item
      this.form = { ...this.item }
    })
    console.log({ s: this.isEditing })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
    submit: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? TaxConfig.api.update(this.item.id)
        : TaxConfig.api.create
      let data = new xMan(this.form).toFormData()
      let message = this.isEditing
        ? 'Tax plan updated successfully'
        : 'Tax plan added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(TaxConfig.events.sorClose))
        // Refetch the indexData
        dispatchEvent(new Event(TaxConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        // todo: remove logs
        console.log(error)
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        this.errors.push('sadf')
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>

<style></style>
